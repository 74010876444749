type Tag = {
  prefix: string,
  description: string,
  icon: string
}
export default () => {
  const SPECIAL_PREFIXES: Array<Tag> = [
    { prefix: 'cmpn:', description: 'Campaign', icon: 'mdi-bullhorn' },
    { prefix: 'kw:', description: 'Sms Keyword', icon: 'mdi-message-bookmark' },
    { prefix: 'cbot:', description: 'Bot', icon: 'mdi-robot' },
    { prefix: 'drip:', description: 'Drip Campaign', icon: 'mdi-multicast' }
  ]
  Object.freeze(SPECIAL_PREFIXES)
  function isSpecialTag (tag: string) {
    return !!tagSpecialPrefix(tag)
  }
  function tagSpecialPrefix (tag: string) {
    return SPECIAL_PREFIXES.find(prefix => tag.startsWith(prefix.prefix))
  }
  function stripTagFromPrefix (tag: string) {
    const prefix = tagSpecialPrefix(tag)
    return prefix ? tag.substring(prefix.prefix.length) : tag
  }
  return {
    SPECIAL_PREFIXES,
    isSpecialTag,
    tagSpecialPrefix,
    stripTagFromPrefix
  }
}
